var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { MAINTAIN_PERMISSION, PRINTLABEL_PERMISSION, CONTAINER_FILLING_MANUALLABEL } from "@kraftheinz/common";
import { M as MAX_INT } from "./validation.js";
import { n as normalizeComponent } from "./index.js";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "edit-manual-label" }, [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("div", { staticClass: "px-2" }, [_c("a-row", { staticClass: "header mb-5 px-4 pb-3", attrs: { "gutter": 16, "type": "flex" } }, [_c("a-col", { staticClass: "d-flex align-items-center flex-wrap", attrs: { "span": 20 } }, [_c("a-icon", { staticClass: "arrow-left mr-3", attrs: { "type": "arrow-left" }, on: { "click": function($event) {
    return _vm.onBack();
  } } }), _c("span", { staticClass: "mr-3 header-title" }, [_c("span", [_vm._v("Container")]), _vm.entity && _vm.entity.containerCode ? _c("span", [_vm._v(" " + _vm._s(" - " + _vm.entity.containerCode) + " ")]) : _vm._e()])], 1)], 1)], 1), _c("div", { staticClass: "body mx-5" }, [_c("a-row", [_c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    return [_c("a-row", { attrs: { "gutter": 24, "type": "flex" } }, [_c("a-col", { staticClass: "mb-5", attrs: { "xs": 24, "xl": 8 } }, [_c("a-card", { style: { height: "100%" } }, [_c("a-spin", { attrs: { "spinning": _vm.isLoading } }, [_c("a-row", [_c("a-col", [_c("text-input", { attrs: { "disabled": !_vm.can(_vm.permissions.maintain), "form-item": "", "label": "Container No", "placeholder": "Type Container No", "rules": "required|max:10", "max-length": 10, "value": _vm.editForm.containerCode }, on: { "change": function($event) {
      return _vm.storeValue("containerCode", $event);
    } } })], 1)], 1), _c("a-row", { staticClass: "pt-3" }, [_c("a-col", [_c("cf-select-input", { attrs: { "disabled": !_vm.can(_vm.permissions.maintain), "form-item": "", "label": "Label Format", "placeholder": "Choose Label Format", "reference": "cf.common.label-formats", "source": "name", "search-by": "Name", "rules": "required", "option-filter": _vm.filterIsInactiveOption, "value": _vm.editForm.labelFormat }, on: { "change": function($event) {
      return _vm.storeValue("labelFormat", $event);
    } } })], 1)], 1), _c("a-row", { staticClass: "pt-3" }, [_c("a-col", [_c("text-input", { attrs: { "disabled": !_vm.can(_vm.permissions.maintain), "form-item": "", "label": "No of Copies", "placeholder": "Type No of Copies", "rules": "numeric|min_value:1|max_value: " + _vm.MAX_INT, "max": _vm.MAX_INT, "value": _vm.editForm.noOfCopies }, on: { "change": function($event) {
      return _vm.storeValue("noOfCopies", $event);
    } } })], 1)], 1), _c("a-row", { staticClass: "pt-3" }, [_c("a-col", [_c("date-picker", { attrs: { "disabled": !_vm.can(_vm.permissions.maintain), "label": "Date and Time", "placeholder": "Select Date and Time", "show-time": "", "format-date": "YYYY-MM-DD HH:mm:ss", "value": _vm.editForm.dateTimeFill }, on: { "change": function($event) {
      return _vm.storeValue("dateTimeFill", $event);
    } } })], 1)], 1), _c("a-row", { staticClass: "pt-3" }, [_c("a-col", [_c("text-input", { attrs: { "disabled": !_vm.can(_vm.permissions.maintain), "form-item": "", "label": "Net Weight", "placeholder": "Type Net Weight", "suffix": "kg", "rules": "number|decimal:4,1", "value": _vm.editForm.nett }, on: { "change": function($event) {
      return _vm.storeValue("nett", $event);
    } } })], 1)], 1), _c("a-row", { staticClass: "pt-3" }, [_c("a-col", [_c("div", { staticClass: "neutral-7--text" }, [_vm._v(" Last changed "), _vm.editForm.updatedBy ? _c("span", [_vm._v("by")]) : _vm._e(), _c("br"), _vm.editForm.updatedBy ? _c("span", [_vm._v(_vm._s(_vm.entity.updatedBy))]) : _vm._e(), _vm._v(" on " + _vm._s(_vm._f("moment")(_vm.entity.updatedAt, "DD/MM/YYYY - hh:mm A")) + " ")])])], 1)], 1)], 1)], 1), _c("a-col", { staticClass: "mb-5", attrs: { "xs": 24, "xl": 16 } }, [_c("a-card", { style: { height: "100%" } }, [_c("a-spin", { attrs: { "spinning": _vm.isLoading } }, [_c("a-row", { attrs: { "gutter": 16 } }, [_c("a-col", { attrs: { "span": 8 } }, [_c("cf-select-input", { attrs: { "disabled": !_vm.can(_vm.permissions.maintain), "form-item": "", "label": "Production Line", "placeholder": "Choose Production Line", "reference": "cf.common.prod-line", "source": "code", "search-by": "Code", "rules": "required", "option-filter": _vm.filterIsInactiveOption, "value": _vm.editForm.prodLineCode }, on: { "change": function($event) {
      return _vm.storeValue("prodLineCode", $event);
    } } })], 1)], 1), _c("a-row", { staticClass: "pt-3", attrs: { "gutter": 16 } }, [_c("a-col", { attrs: { "span": 8 } }, [_c("cf-select-input", { attrs: { "disabled": !_vm.can(_vm.permissions.maintain), "form-item": "", "label": "Item Code", "placeholder": "Choose Item Code", "reference": "cf.common.items", "source": "code", "search-by": "Code", "all-in-value": "", "option-filter": _vm.filterIsInactiveOption, "value": _vm.editForm.itemCode }, on: { "change": _vm.onItemCodeChange } })], 1), _c("a-col", { attrs: { "span": 16 } }, [_c("text-input", { attrs: { "form-item": "", "label": "Item Code Description", "rules": "max:50", "max-length": 50, "disabled": true, "value": _vm.editForm.itemDescription } })], 1)], 1), _c("a-row", { staticClass: "pt-3", attrs: { "gutter": 16 } }, [_c("a-col", { attrs: { "span": 8 } }, [_c("label", [_vm._v("Customer")]), _c("a-auto-complete", { staticClass: "mt-1", staticStyle: { "width": "100%" }, attrs: { "disabled": !_vm.can(_vm.permissions.maintain), "option-label-prop": "selectedCustomer", "placeholder": "Type Customer" }, on: { "select": _vm.onCustomerSelect, "search": _vm.onCustomerSearch }, model: { value: _vm.editForm.customerNo, callback: function($$v) {
      _vm.$set(_vm.editForm, "customerNo", $$v);
    }, expression: "editForm.customerNo" } }, [_c("template", { slot: "dataSource" }, [_vm.customers.length > 0 ? _c("a-select-option", { key: "label" }, [_c("div", { staticClass: "d-flex" }, [_c("div", { staticStyle: { "width": "50%" } }, [_vm._v("Number")]), _c("div", { staticStyle: { "width": "50%" } }, [_vm._v("Name")])])]) : _vm._e(), _vm._l(_vm.customers, function(customer) {
      return _c("a-select-option", { key: customer.id, attrs: { "value": JSON.stringify(customer) } }, [_c("div", { staticClass: "d-flex" }, [_c("div", { staticStyle: { "width": "50%" } }, [_vm._v(_vm._s(customer.customerNumber))]), _c("div", { staticStyle: { "width": "50%" } }, [_vm._v(_vm._s(customer.customerName))])])]);
    })], 2)], 2)], 1), _c("a-col", { attrs: { "span": 16 } }, [_c("text-input", { attrs: { "form-item": "", "label": "Customer Name", "disabled": true, "rules": "max:50", "max-length": 50, "value": _vm.editForm.customerName } })], 1)], 1), _c("a-row", { staticClass: "pt-3" }, [_c("a-col", [_c("text-input", { attrs: { "form-item": "", "label": "Address 1", "rules": "max:100", "max-length": 100, "disabled": true, "value": _vm.editForm.customerAddress1 } })], 1)], 1), _c("a-row", { staticClass: "pt-3" }, [_c("a-col", [_c("text-input", { attrs: { "form-item": "", "label": "Address 2", "rules": "max:100", "max-length": 100, "disabled": true, "value": _vm.editForm.customerAddress2 } })], 1)], 1), _c("a-row", { staticClass: "pt-3", attrs: { "gutter": 16 } }, [_c("a-col", { attrs: { "span": 8 } }, [_c("text-input", { attrs: { "disabled": !_vm.can(_vm.permissions.maintain), "form-item": "", "label": "Label Description 1", "placeholder": "Type Label Description 1", "max-length": 10, "value": _vm.editForm.labelDesc1 }, on: { "change": function($event) {
      return _vm.storeValue("labelDesc1", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 8 } }, [_c("text-input", { attrs: { "disabled": !_vm.can(_vm.permissions.maintain), "form-item": "", "label": "Label Description 2", "placeholder": "Type Label Description 2", "max-length": 15, "value": _vm.editForm.labelDesc2 }, on: { "change": function($event) {
      return _vm.storeValue("labelDesc2", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 8 } }, [_c("text-input", { attrs: { "disabled": !_vm.can(_vm.permissions.maintain), "form-item": "", "label": "Conditional Comment", "placeholder": "Type Conditional Comment", "rules": "max:10", "max-length": 10, "value": _vm.editForm.conditionalComment }, on: { "change": function($event) {
      return _vm.storeValue("conditionalComment", $event);
    } } })], 1)], 1)], 1)], 1)], 1)], 1), _c("div", { staticClass: "ant-modal-footer p-3" }, [_vm.can(_vm.permissions.printlabel) ? _c("a-button", { attrs: { "type": "primary", "ghost": "" }, on: { "click": _vm.onPrintClick } }, [_vm._v("Print Label")]) : _vm._e(), _vm.can(_vm.permissions.maintain) ? _c("a-button", { on: { "click": _vm.onBack } }, [_vm._v("Cancel")]) : _vm._e(), _vm.can(_vm.permissions.maintain) ? _c("a-button", { attrs: { "type": "primary", "loading": _vm.isLoading }, on: { "click": function($event) {
      return handleSubmit(_vm.onSave);
    } } }, [_vm._v("Save")]) : _vm._e()], 1)];
  } }]) })], 1)], 1)], 1);
};
var staticRenderFns$1 = [];
var EditManualLabel_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script$1 = {
  name: "EditManualLabel",
  inject: ["resourceProps", "can"],
  data() {
    const [manualLabelProps, customerProps] = this.resourceProps;
    return {
      customerProps,
      manualLabelProps,
      entity: {},
      editForm: {},
      permissions: {
        maintain: MAINTAIN_PERMISSION,
        printlabel: PRINTLABEL_PERMISSION
      },
      isLoading: true,
      MAX_INT
    };
  },
  computed: {
    itemsMenu() {
      const itemsMenu = [
        {
          key: "containerprocessing",
          title: "Container Processing",
          path: ""
        },
        {
          key: "manuallabel",
          title: "Manual Label",
          path: "/container-filling/manual-label"
        },
        {
          key: `${this.entity && this.entity.containerCode}`,
          title: `${this.entity && this.entity.containerCode}`,
          path: `/container-filling/manual-label/${this.entity && this.entity.id}`
        }
      ];
      return itemsMenu;
    },
    customers() {
      return this.customerProps.crud.getList();
    }
  },
  async mounted() {
    try {
      this.isLoading = true;
      await this.manualLabelProps.crud.fetchEntity(), this.entity = this.manualLabelProps.crud.getEntity();
      this.storeValues(this.entity);
    } catch (err) {
      this.onBack();
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    onBack() {
      this.$router.push("/container-filling/manual-label");
    },
    storeValue(resourceKey, value) {
      this.editForm = __spreadProps(__spreadValues({}, this.editForm), {
        [resourceKey]: value
      });
      this.manualLabelProps.crud.updateEntity("editForm", {
        resourceKey,
        value
      });
    },
    storeValues(entity) {
      Object.entries(entity).forEach(([resourceKey, value]) => this.storeValue(resourceKey, value));
    },
    onSave() {
      this.isLoading = true;
      this.manualLabelProps.crud.submitEntity("replace").then(() => {
        this.isLoading = false;
        this.$router.push("/container-filling/manual-label");
      }).catch(() => this.isLoading = false);
    },
    onItemCodeChange(value) {
      if (!value) {
        this.storeValue("itemCode", "");
        this.storeValue("itemDescription", "");
        return;
      }
      this.storeValue("itemCode", value.code);
      this.storeValue("itemDescription", value.description);
    },
    onCustomerSelect(selectedCustomer) {
      try {
        const customer = JSON.parse(selectedCustomer);
        this.storeValue("customerNo", customer.customerNumber);
        this.storeValue("customerName", customer.customerName);
        this.storeValue("customerAddress1", customer.address1);
        this.storeValue("customerAddress2", customer.address2);
      } catch (e) {
        this.$notification["error"]({
          message: "Errors encountered. Please try again!"
        });
      }
    },
    async getCustomerSuggestionList() {
      await this.customerProps.crud.fetchList(`${this.customerProps.apiUrl}/cf/common/customers`);
    },
    onCustomerSearch(searchTerm) {
      if (!searchTerm)
        return;
      this.customerProps.crud.deleteFilter("IsInactive");
      this.customerProps.crud.setFilter("CustomerNumber", {
        operator: "contains",
        value: searchTerm
      });
      this.customerProps.crud.getFilterQuery();
      this.getCustomerSuggestionList();
    },
    onPrintClick() {
      this.$router.push(`/container-filling/manual-label/${this.entity.id}/print`);
    },
    filterIsInactiveOption(option) {
      return !option.isInactive;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "138149d3", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditManualLabel = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "edit-manual-label" }, [_c("resource", { attrs: { "name": "cf.common.label-formats", "api-url": _vm.apiUrl, "resource-id-name": "name" } }), _c("resource", { attrs: { "name": "cf.common.prod-line", "api-url": _vm.apiUrl, "resource-id-name": "code" } }), _c("resource", { attrs: { "name": "cf.common.items", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": ["cf.manual-labels", "cf.common.customers"], "api-url": _vm.apiUrl, "redirect-route": ["/container-filling/manual-labels"], "resource-id-name": ["id", "customerNumber"], "page": _vm.page } }, [_c("edit-manual-label")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    EditManualLabel
  },
  data() {
    return {
      apiUrl,
      page: CONTAINER_FILLING_MANUALLABEL
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
